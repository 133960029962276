import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
// 2022-10-25 bolo: import router from "./router";
import router from "@/router/trixi-router"; // .ts

import store from "./store";

import ElementPlus from "element-plus";  // dolezite, napr. spristupnuje "el-input"
import i18n from "@/core/plugins/i18n";
//import mitt from 'mitt';

//imports for app initialization
import ApiService from "@/core/services/ApiService";
//import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

//import "@/core/plugins/prismjs";


const app = createApp(App);
// toto uz nebolo treba app.config.globalProperties.$store = store;

app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
//initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
