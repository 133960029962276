import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    //redirect: "/",  // toto je presmerovanie prazdne URL na home page, okrem tohto ta stranka 'vlastnosti' musi existovat ako child.."
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      // NO AUTH 2023-01-09 - DO NOT DEL! middleware: "auth",
    },


    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/Domovska-Stranka.vue"),
        meta: {
          pageTitle: "App-001",
          breadcrumbs: ["APP-001"],
        },
      }, // end one route

      {
        path: "/vlastnosti",
        name: "vlastnosti",
        component: () => import("@/views/Vlastnosti-Stranka.vue"),
        meta: {
          pageTitle: "App-001",
          breadcrumbs: ["APP-001"],
        },
      }, // end one route

      {
        path: "/analyza",
        name: "analyza",
        component: () => import("@/views/App-Hlavne-Okno.vue"),
        meta: {
          pageTitle: "App-002",
          breadcrumbs: ["APP-002"],
        },
      }, // end one route

      {
        path: "/dokumentacia",
        name: "dokumentacia",
        component: () => import("@/views/Dokumentacia-Okno.vue"),
        meta: {
          pageTitle: "App-003",
          breadcrumbs: ["APP-003"],
        },
      }, // end one route

      {
        path: "/ceny",
        name: "ceny",
        component: () => import("@/views/Ceny-Okno.vue"),
        meta: {
          pageTitle: "App-004",
          breadcrumbs: ["APP-004"],
        },
      }, // end one route

      {
        path: "/kontakt",
        name: "kontakt",
        component: () => import("@/views/Kontakt-Okno.vue"),
        meta: {
          pageTitle: "App-005",
          breadcrumbs: ["APP-005"],
        },
      }, // end one route





      // LABEL-2022-11-21_001  (nemazat!)
      // ---------------------------------

      // ===========
      // MOJ WIZARD 1:
      // ==========
      /*

      {
        path: "/pridaj-byt-stary-krok1",
        name: "pridaj-byt-stary-krok1",
        component: () => import("@/views/trixi/PridajBytWizard1-stary/PridajBytWizardStep1.vue"),
        meta: {
          pageTitle: "Pridaj byt - stary - krok 1",
          breadcrumbs: ["breadcrumbs-Pages-S001", "breadcrumbs-Wizard-S002"],  // nazvy, aby sa dali neskor najst
        },
      }, // end route
      {
        path: "/pridaj-byt-stary-krok2",
        name: "pridaj-byt-stary-krok2",
        component: () => import("@/views/trixi/PridajBytWizard1-stary/PridajBytWizardStep2.vue"),
        meta: {
          pageTitle: "Pridaj byt - stary - krok 2",
          breadcrumbs: ["breadcrumbs-Pages-S011", "breadcrumbs-Wizard-S012"],  // nazvy, aby sa dali neskor najst
        },
      }, // end route
      {
        path: "/pridaj-byt-stary-krok3",
        name: "pridaj-byt-stary-krok3",
        component: () => import("@/views/trixi/PridajBytWizard1-stary/PridajBytWizardStep3.vue"),
        meta: {
          pageTitle: "Pridaj byt - stary - krok 3",
          breadcrumbs: ["breadcrumbs-Pages-S021", "breadcrumbs-Wizard-S022"],  // nazvy, aby sa dali neskor najst
        },
      }, // end route
      {
        path: "/pridaj-byt-stary-krok4",
        name: "pridaj-byt-stary-krok4",
        component: () => import("@/views/trixi/PridajBytWizard1-stary/PridajBytWizardStep4.vue"),
        meta: {
          pageTitle: "Pridaj byt - stary - krok 4",
          breadcrumbs: ["breadcrumbs-Pages-S031", "breadcrumbs-Wizard-S032"],  // nazvy, aby sa dali neskor najst
        },
      }, // end route
      {
        path: "/pridaj-byt-stary-krok5",
        name: "pridaj-byt-stary-krok5",
        component: () => import("@/views/trixi/PridajBytWizard1-stary/PridajBytWizardStep5.vue"),
        meta: {
          pageTitle: "Pridaj byt - stary - krok 5",
          breadcrumbs: ["breadcrumbs-Pages-S041", "breadcrumbs-Wizard-S042"],  // nazvy, aby sa dali neskor najst
        },
      }, // end route
      {
        path: "/pridaj-byt-stary-krok6",
        name: "pridaj-byt-stary-krok6",
        component: () => import("@/views/trixi/PridajBytWizard1-stary/PridajBytWizardStep6.vue"),
        meta: {
          pageTitle: "Pridaj byt - stary - krok 6",
          breadcrumbs: ["breadcrumbs-Pages-S051", "breadcrumbs-Wizard-S052"],  // nazvy, aby sa dali neskor najst
        },
      }, // end route
      {
        path: "/pridaj-byt-stary-krok7",
        name: "pridaj-byt-stary-krok7",
        component: () => import("@/views/trixi/PridajBytWizard1-stary/PridajBytWizardStep7.vue"),
        meta: {
          pageTitle: "Pridaj byt - stary - krok 7",
          breadcrumbs: ["breadcrumbs-Pages-S061", "breadcrumbs-Wizard-S062"],  // nazvy, aby sa dali neskor najst
        },
      }, // end route

        */


      // ===========
      // MOJ WIZARD 2: (2023-02-06)
      // ==========

      {
        path: "/pridaj-byt-krok1",
        name: "pridaj-byt-krok1",
        component: () => import("@/views/trixi/PridajBytWizard2/PridajBytWizard2-Step1.vue"),
        meta: {
          pageTitle: "Pridaj byt - krok 1",
          breadcrumbs: ["breadcrumbs-Pages-A001", "breadcrumbs-Wizard-A001"],  // nazvy, aby sa dali neskor najst
        },
      }, // end route


      {
        path: "/pridaj-byt-krok2",
        name: "pridaj-byt-krok2",
        component: () => import("@/views/trixi/PridajBytWizard2/PridajBytWizard2-Step2.vue"),
        meta: {
          pageTitle: "Pridaj byt - krok 2",
          breadcrumbs: ["breadcrumbs-Pages-A002", "breadcrumbs-Wizard-A002"],  // nazvy, aby sa dali neskor najst
        },
      }, // end route


      // ===========
      // MOJ WIZARD 3: (2023-02-08)
      // ==========

      {
        path: "/pridaj-udalost-krok1",
        name: "pridaj-udalost-krok1",
        component: () => import("@/views/trixi/PridajBytWizard3/PridajBytWizard3-Step1.vue"),
        meta: {
          pageTitle: "Pridaj udalost - krok 1",
          breadcrumbs: ["breadcrumbs-Pages-B001", "breadcrumbs-Wizard-B001"],  // nazvy, aby sa dali neskor najst
        },
      }, // end route

      {
        path: "/pridaj-udalost-krok2",
        name: "pridaj-udalost-krok2",
        component: () => import("@/views/trixi/PridajBytWizard3/PridajBytWizard3-Step2.vue"),
        meta: {
          pageTitle: "Pridaj udalost - krok 2",
          breadcrumbs: ["breadcrumbs-Pages-B002", "breadcrumbs-Wizard-B002"],  // nazvy, aby sa dali neskor najst
        },
      }, // end route

      {
        path: "/pridaj-udalost-krok3",
        name: "pridaj-udalost-krok3",
        component: () => import("@/views/trixi/PridajBytWizard3/PridajBytWizard3-Step3.vue"),
        meta: {
          pageTitle: "Pridaj udalost - krok 3",
          breadcrumbs: ["breadcrumbs-Pages-B003", "breadcrumbs-Wizard-B003"],  // nazvy, aby sa dali neskor najst
        },
      }, // end route

      {
        path: "/pridaj-udalost-krok4",
        name: "pridaj-udalost-krok4",
        component: () => import("@/views/trixi/PridajBytWizard3/PridajBytWizard3-Step4.vue"),
        meta: {
          pageTitle: "Pridaj udalost - krok 4",
          breadcrumbs: ["breadcrumbs-Pages-B004", "breadcrumbs-Wizard-B004"],  // nazvy, aby sa dali neskor najst
        },
      }, // end route

      {
        path: "/pridaj-udalost-krok5",
        name: "pridaj-udalost-krok5",
        component: () => import("@/views/trixi/PridajBytWizard3/PridajBytWizard3-Step5.vue"),
        meta: {
          pageTitle: "Pridaj udalost - krok 5",
          breadcrumbs: ["breadcrumbs-Pages-B005", "breadcrumbs-Wizard-B005"],  // nazvy, aby sa dali neskor najst
        },
      }, // end route

    ],
  },



  /* NO AUTH 2023-01-09 - DO NOT DEL!
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  */


  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },


  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // verify auth token before each page change
  //store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // before page access check if page requires authentication

  /* NO AUTH 2023-01-09 - DO NOT DEL!
  if (to.meta.middleware == "auth") {
    if (store.getters.isUserAuthenticated) {
      next();
    } else {
      next({ name: "sign-in" });
    }
  } else  */ {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
