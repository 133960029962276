import Vue, {toRaw} from 'vue'
import {forEach} from "core-js/stable/dom-collections";

/* GYU FRAMEWORK VERSION 2.0
2022-11-08
 */

export default {


    methods: {


        // bez portu
        _getUrl() {
//      ------------
            if (this._isDevelopment()) return "http://trixi.znalec.localhost";
            else return "https://trixi.znalec.sk";
        },

        _getKontoUrl() {
//      -------------
            if (this._isDevelopment()) return "http://konto.znalec.localhost";
            else return "https://konto.znalec.sk";
        },

        _getApiUrl() {
//      ---------------
            if (this._isDevelopment())return "http://trixi-api.znalec.localhost";
            else return "https://trixi-api.znalec.sk";
        },

        _getPort() {
//      --------------
            if (this._isDevelopment())return ":8080";  // treba zadat aj dvojbodku
            else return "";
        },

        _getApiPort() {
//      ----------------
            if (this._isDevelopment())return ":8080";  // treba zadat aj dvojbodku
            else return "";
        },


        /**
         * http://stackoverflow.com/questions/10270648/determine-if-javascript-value-is-an-integer
         * @param param
         *
         * testovanie:
         * console.log ('5555555: ' + this.isInteger(5555555));
         * console.log ('0: ' + this.isInteger(0));
         * console.log ('-0: ' + this.isInteger(-0));
         * console.log ('-2000: ' + this.isInteger(-2000));
         * console.log ('5005: ' + this.isInteger(5005));
         * console.log ('++100000: ' + this.isInteger('++100000'));
         * console.log ('--5: ' + this.isInteger('--5'));
         * console.log ('0.1: ' + this.isInteger(0.1));
         * console.log ('0,2: ' + this.isInteger('0,2'));
         * console.log ('0..3: ' + this.isInteger('0..3'));
         * @return boolean
         */
        _isInteger (param) {
//      -------------------
            if (Math.floor(param) == param && this._isNumeric(param)) return true;
            else return false;
        },


        /**
         * Kontroluje, ci je to cele alebo desatinne cislo
         * @param param
         * @param convert - ak je true, filtruje aj vselijake medzery a konvertuje desatinnu ciarku na bodku,
         * @returns {boolean}

         * testovanie:
         * console.log ('5555555: ' + this.isNumeric(5555555));
         * console.log ('0: ' + this.isNumeric(0));
         * console.log ('-0: ' + this.isNumeric(-0));
         * console.log ('-2000: ' + this.isNumeric(-2000));
         * console.log ('5005: ' + this.isNumeric(5005));
         * console.log ('++100000: ' + this.isNumeric('++100000'));
         * console.log ('--5: ' + this.isNumeric('--5'));
         * console.log ('0.1: ' + this.isNumeric(0.1));
         * console.log ('0,2: ' + this.isNumeric('0,2'));
         * console.log ('0..3: ' + this.isNumeric('0..3'));
         */
        _isNumeric (param, convert = true) {
//      -----------------------------------------------
            param = String (param);
            if (convert) {
                param = String (param);
                param = param.replace('&nbsp;', '');
                param = param.replace(' ', '');
                param = param.replace(' ', '');  // ALT-0160 - ez is kell!
                param = param.replace(',', '.');
            }
            let ValidChars = "0123456789.+-";
            let IsNumber=true;
            let Char;
            let dot_counter = 0;
            let plus_counter = 0;
            let minus_counter = 0;
            param.replace (',', '.');

            for (let i = 0; i < param.length && IsNumber == true; i++) {
                Char = param.charAt(i);
                if (ValidChars.indexOf(Char) == -1) IsNumber = false;

                // csak egyszer lehet benne . karakter
                if (Char == '.')  dot_counter = dot_counter  + 1;
                if  (dot_counter > 1)  IsNumber = false;

                // csak egyszer lehet benne + karakter
                if (Char == '+')  plus_counter = plus_counter  + 1;
                if  (plus_counter > 1)  IsNumber = false;

                // csak egyszer lehet benne - karakter
                if (Char == '-')  minus_counter = minus_counter  + 1;
                if  (minus_counter > 1)  IsNumber = false;
            }
            if (param == '') IsNumber = false;

            return IsNumber;
        }, // end func.

        _convertFloatToSlovak (param) {
//      -----------------------------------
            param = param.toString();
            return this._trimTotal(param.replace('.', ','));
        },  // end func.


        _formatFloatToSlovak (param, decimals = 2) {
//      ----------------------------------------------------
            //https://www.codingem.com/comma-thousand-separator-in-javascript/
            param = parseFloat(param);
            if (isNaN(param)) param = 0;
            param = param.toFixed(decimals);
            let parts = param.toString().split(".");
            let numberPart = parts[0];
            let decimalPart = parts[1];
            let thousands = /\B(?=(\d{3})+(?!\d))/g;
            let temp = numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
            temp = temp.replaceAll(',', ' ');
            temp = temp.replaceAll('.', ',');
            return temp;
        }, // end func.

        _formatIntegerToSlovak (param) {
//      --------------------------------
            return this._formatFloatToSlovak (param,0);
        }, // end func.



        _convertFloatToEnglish (param) {
//      --------------------------------
            param = String (param);
            param = param.replaceAll(',', '.');
            param = param.replaceAll(' ', '');
            param = param.replaceAll('&nbsp;', '');
            return param;
        },  // end func.


        _isDevelopment: function () {
//      ----------------------------
            // oficialne by bolo toto:
            // if (process.env.NODE_ENV === 'development') return true;
            if (window.location.href.indexOf("localhost") > -1) {
                return true
            }
            return false
        }, // end func.


        _consoleLog: function (param) {
//      ------------------------------
            console.log(param);
        }, // end func.

        _consoleLogDevelopment: function (param) {
//      -----------------------------------------
            if (this._isDevelopment()) console.log(param);
        }, // end func.


        _consoleClear: function () {
//      ------------
            console.clear();
        }, // end func.


        _consoleLogRaw (param) {
//      -----------------------
            console.log (toRaw(param));
        }, // en func.


        /**
         * 2022-10-11
         * Vzdy vracia float, ak je to NaN, tak vracia nulu
         * @param param
         * @return float
         */
        _normalizeFloat: function (param) {
//      ----------------------------------
            param = String (param);
            param = param.replace('&nbsp;', '');
            param = param.replace(' ', '');
            param = param.replace(' ', '');  // ALT-0160 - ez is kell!
            param = param.replace(',', '.');
            if (isNaN(param)) param = '0';
            return parseFloat(param);
        },  // end func.


        /**
         * Funkcia, ktora na akykolvek parameter akehojolvek druhu (napr. String) vrati
         * cislo typu float, ak ten parameter je numericky, alebo vrati float 0,
         * ak sa ten parameter neda interpretovat ako normalne cislo
         * Nefunguje na exponencialne zapisy!!!
         * 2022-12-02
         */
        _forceFloat: function (param) {
//      ------------------------------
            let res = this._convertFloatToEnglish(this._trimTotal(param));
            if (this._isNumeric(res)) return parseFloat(res);
            else return parseFloat(0);
        }, // end func.


        /**
         * 2023-01-13
          * @param param
         * @private
         */
        _forceInt: function (param) {
//      -----------------------------
            return parseInt(this._forceFloat(param));
        }, // end func


        /**
         *
         * @param String
         * @return String
         */
        _trimTotal (param) {
//      -----------------------
            return this._replaceAllSubstrings(param, ' ','');
        }, // end func.


        /**
         * https://dmitripavlutin.com/replace-all-string-occurrences-javascript/
         * @param string
         * @param search
         * @param replace
         * @return string
         * 2022-10-21
         */
        _replaceAllSubstrings (string, search, replace) {
//      --------------------------------------------
            //return String (string.split(search).join(replace)) ;
            string = String (string);
            return string.replace(new RegExp(search, 'g'), replace);
        }, // end func.


        /**
         * https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid
         * 2022-11-29
         */
        _genUuidv4() {
//      -------------
            return  String(
                ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))
            );
        }, // end func.,



        /* --------------------------- */
        /* STRING                      */
        /* --------------------------- */


        _getLastChars(string, last_char_count = 1) {
//      -----------------------------------------------------
            string = String(string);
            if (string.length >= last_char_count) {
                return string.substr(string.length - last_char_count);
            } else {
                return '';
            }
        }, // end func,


        /**
         * 2023-02-08
         * @param attribute
         * @param name
         * @private
         */
        _getNodevalueFromAttributes(attribute, name = '') {
//      ---------------------------------------------------------
            name = name.toLowerCase();
            //this._consoleLogRaw (attribute.length);
            if (attribute.length > 0) {
                for (let f = 0; f <= attribute.length; f++) {

                    if (attribute[f] != undefined && attribute[f].name != undefined && attribute[f].name == name && attribute[f].value != undefined) {

                        //this._consoleLogRaw(attribute[f].value);
                        return attribute[f].value;
                    }
                }
            }
            return false;

            //this._consoleLogRaw(attribute);
            //this._consoleLogRaw(value);


        }, // end func.






        /* --------------------------- */
        /* --------------------------- */
        /* PROJECT - SPECIFIC FUNCIONS */
        /* --------------------------- */
        /* --------------------------- */







    },
}

