/* eslint-disable */
<template>

  <router-view />

</template>

<script >
import {defineComponent, nextTick, onMounted, provide, toRaw } from "vue";
import { useStore } from "vuex";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { themeMode } from "@/core/helpers/config";
import { initializeComponents } from "@/core/plugins/keenthemes";
import axios from "axios";


import gyu_framework from '@/views/mixins/gyu_framework.js';


export default defineComponent({
  mixins: [gyu_framework],
  name: "app",


  created() {
//============


    // nacitanie uzivatelskych dat
    // toto bude nova URL: onst dataURL = this._getApiUrl() + '/api/userdata?a=b&b=2&c=3';  // SKUSKA 2023-02-05
    const dataURL = this._getApiUrl() + '/api/userdata';
    // prechodne: const dataURL = 'https://trixi-api.mandelbrot.cloud' + '/api/getUserData';



    const config = {
      withCredentials: true, /// !!!! Toto bolo najtazsie rozchodit, lebo CORS....  VUE.xls::LABEL-2023-01-18
      crossdomain: true,
    }
    // default values:
    // 2023-01-09 zda sa, ze nasl. 2 riadky, teda inicializacia premennych musia byt tu, a nie vo vetve catch() nizsie,
    // (kde by teoreticky tiez mohli byt), lebo ak by boli tam, axios niekedy nahodne nenacita udaje a nepomaha ziadny await a podobne.
    this.$store.state.myData = {};
    this.$store.state.myData.isData = false;
    //console.clear();

    // axios:
    axios.get(dataURL, config)
        .then(response => {
          console.log ('Axios-001 success!');

          // musi to byt objekt
          if (typeof response.data == "object") {
            this.$store.state.myData = response.data;
            this.$store.state.myData.isData = true;
          // inak tiez chyba
          } else {
            console.log ('Axios-001-A error!');
            this.$store.state.myData.isData = false;
          }
        })
        .catch(errors => {
          // ostanu v platnosti default udaje pre this.$store.state.myData  a vypise chybu
          console.log ('Axios-001-B error!');
          console.log (errors);  // udajne vzdy tam musi byt to 'response'
        })




    /*
    // nacitanie uzivatelskych dat
    const dataURL = this._getApiUrl() + '/api/getUserData';

    //const dataURL = 'https://trixi-api.mandelbrot.cloud' + '/api/getUserData';

    const config = {
      withCredentials: true, /// !!!! Toto bolo najtazsie rozchodit, lebo CORS....  VUE.xls::LABEL-2023-01-18
      crossdomain: true,
    }
    // default values:
    // 2023-01-09 zda sa, ze nasl. 2 riadky, teda inicializacia premennych musia byt tu, a nie vo vetve catch() nizsie,
    // (kde by teoreticky tiez mohli byt), lebo ak by boli tam, axios niekedy nahodne nenacita udaje a nepomaha ziadny await a podobne.
    this.$store.state.myData = {};
    this.$store.state.myData.isData = false;

    // axios:
    axios.get(dataURL, config)
        .then(response => {
          console.log ('Axios-001 success!');
          this.$store.state.myData = response.data;
          this.$store.state.myData.isData = true;
        })
        .catch(errors => {
          // ostanu v platnosti default udaje pre this.$store.state.myData  a vypise chybu
          console.log ('Axios-001 error!');
          console.log (errors.response);  // udajne vzdy tam musi byt to 'response'
        })
*/
  }, // end created


    setup() {
//  =========
      const store = useStore();

     onMounted(() => {
        /**
         * Overrides the layout config using saved data from localStorage
         * remove this to use static config (@/core/config/DefaultLayoutConfig.ts)
         */
        store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

        /**
         *  Sets a mode from configuration
         */
        store.dispatch(Actions.SET_THEME_MODE_ACTION, themeMode.value);
        //provide('leaflet_map_01', null);  // !!! globalna premenna

        nextTick(() => {
          initializeComponents();

          store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
        });
      });
  },  // end setup
});


</script>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~element-plus/dist/index.css";

// Main demo style scss
@import "assets/sass/element-ui.dark";
@import "assets/sass/plugins";
@import "assets/sass/style";

//RTL version styles
//@import "assets/css/style.rtl.css";

#app {
  display: contents;
}
</style>
